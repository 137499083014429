exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-custom-number-format-js": () => import("./../../../src/pages/components/customNumberFormat.js" /* webpackChunkName: "component---src-pages-components-custom-number-format-js" */),
  "component---src-pages-components-details-copy-js": () => import("./../../../src/pages/components/details-copy.js" /* webpackChunkName: "component---src-pages-components-details-copy-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-instructional-block-js": () => import("./../../../src/pages/components/instructional-block.js" /* webpackChunkName: "component---src-pages-components-instructional-block-js" */),
  "component---src-pages-components-progress-bar-js": () => import("./../../../src/pages/components/progress-bar.js" /* webpackChunkName: "component---src-pages-components-progress-bar-js" */),
  "component---src-pages-components-sidebar-js": () => import("./../../../src/pages/components/sidebar.js" /* webpackChunkName: "component---src-pages-components-sidebar-js" */),
  "component---src-pages-components-stat-panel-js": () => import("./../../../src/pages/components/stat-panel.js" /* webpackChunkName: "component---src-pages-components-stat-panel-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

